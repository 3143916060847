<!-- 楼栋管理 -->
<template>
  <div style="height: 100%">
    <a-row style="height: 100%">
      <a-col :span="4">
        <!-- 左边竖线样式写法 -->
        <div class="titleBox">
          <div class="titleLine" style=""></div>
          <div>楼栋管理</div>
        </div>
        <div style="margin-top: 3%; padding-right: 2%">
          <el-input
            placeholder="输入关键字进行过滤"
            prefix-icon="el-icon-search"
            v-model="filterText"
          >
          </el-input>
          <el-tree
            class="filter-tree"
            :data="data"
            @node-click="treeClick"
            :filter-node-method="filterNode"
            ref="tree"
          >
          </el-tree>
        </div>
      </a-col>
      <a-col
        :span="20"
        style="height: 100%; border-left: 1px solid #d8d8d8; padding: 1%"
      >
        <ax-table
          ref="roleTable"
          :columns="columns"
          :show-search="true"
          :toolActions="toolActions"
          :searchForm="searchForm"
          @add="add"
          :sqlParams="sqlParams"
          :dataSourceApi="api.list"
          @bulkDeletion="deleteSecurityPersonnel"
          :dataSourceParams="dataSourceParams"
          @pop-confirm="popConfirm"
          @export="expor"
          @action-column-click="actionColumnClick"
          :scroll="{ y: '55vh', x: '80vw' }"
          :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
          <div slot="locationStasus" slot-scope="{ record }">
            <div
              class="plotting"
              v-if="record.latitude !== '' && record.latitude !== null"
            >
              <div style="background-color: #f6ffed; color: #67c23a">
                已定位
              </div>
            </div>
            <div class="plotting" v-else>
              <div style="background-color: #e6e9ee">未定位</div>
            </div>
          </div>
        </ax-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import api from "../api";

import { isEmpty, initGridFormData } from "../../../../../../common/tools";
const duty = [
  { label: "未定位", value: "0" },
  { label: "已定位", value: "1" },
];
export default {
  name: "asset",
  data() {
    return {
      api,
      treeChoice: "",
      tableData: [],
      gridName: "",
      level: "",
      selectedRowKeys: [],
      loading: false,
      tableData: [],
      formBuilder: initGridFormData(
        [],
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      searchForm: this.$common.initGridFormData(
        [
          {
            label: "关键字",
            type: "",
            model: "keyword",
            options: { placeholder: "网格名称/楼长名" },
            col: { span: 6 },
          },
          {
            label: "定位状态",
            type: "select",
            model: "locationStasus",
            options: { placeholder: "选择定位", options: duty },
            col: { span: 6 },
          },
        ],
        { layout: "horizontal", labelWidth: 70 }
      ),
      dataSourceParams: { gridId: "" },
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "小区名称",
            dataIndex: "cellName",
            width: 180,
            ellipsis: false,
          },
          {
            title: "房屋号",
            dataIndex: "blockCode",
            ellipsis: false,
            width: 120,
          },
          {
            title: "单元数",
            dataIndex: "unitNumber",
            ellipsis: false,
            width: 120,
          },

          {
            title: "实有户数",
            dataIndex: "actualHousehold",
            ellipsis: false,
            width: 120,
          },
          {
            title: "楼长名",
            dataIndex: "managerName",
            ellipsis: false,
            width: 180,
          },
          {
            title: "楼长电话",
            dataIndex: "managerPhone",
            ellipsis: false,
            width: 180,
          },
          {
            title: "入住率",
            dataIndex: "occupationRate",
            ellipsis: false,
            width: 120,
          },
          {
            title: "定位状态",
            dataIndex: "locationStasus",
            ellipsis: false,
            width: 180,
            align: "center", // 设置内容左对齐
          },
        ],
        true,
        {
          fixed: "right",
          width: 300,
          actions: [
            {
              props: (record) => {
                return {
                  text: "房屋管理",
                  name: "unitFloor",
                  type: "#2A5CFF",
                  link: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "楼栋可视化",
                  name: "visual",
                  type: "#e8ae56",
                  link: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#ff444a",
                  link: true,
                  popDisabled: false,
                  title: "确认删除？",
                };
              },
            },
          ],
        }
      ),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [
        { name: "add", text: "新增房屋" },
        { name: "export", text: "导出", type: "#67c23a" },
        { name: "bulkDeletion", text: "批量删除", type: "#FA5959" },
      ],
      sqlParams: {
        like: ["userName", "duty"],
      },
      // 左树
      filterText: "",
      data: [],
      treeID: "",
    };
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {},
  mounted() {
    this.getgridTree();
  },
  methods: {
    // 导出
    expor() {
      api.export({
        fileName: "楼栋管理.xls",
        params: "",
      });
    },
    // 树过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 递归处理树结构
    mapChildren(children) {
      if (!children || children.length === 0) {
        return [];
      }
      return children.map((child) => ({
        label: child.name,
        key: child.id,
        value: child.id,
        level: child.level,
        children: this.mapChildren(child.children), // 递归处理子节点
      }));
    },
    // 获取网格点
    async getgridTree() {
      try {
        const res = await api.gridTree();
        let treeData = res.data.map((res) => {
          return {
            label: res.name,
            key: res.id,
            level: res.level,
            value: res.id,
            children: this.mapChildren(res.children),
          };
        });
        this.data = treeData;
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    // 树点击
    async treeClick(e) {
      if (e.value) {
        this.dataSourceParams.gridId = e.value;
        this.$refs.roleTable.getDataSource();
        this.treeID = e.value;
      }
      if (e.label) {
        this.gridName = e.label;
      }
      if (e.level) {
        this.level = e.level;
      }
    },

    add() {
      this.$router.push({
        path: "/views/communityGovernance/housingList/buildingList/Management/components/add.vue",
        query: {
          gridName: this.gridName,
          treeID: this.treeID,
          level: this.level,
        },
      });
    },
    //操作区域
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //房屋管理
        case "unitFloor":
          this.$router.push({
            path: "/views/communityGovernance/housingList/buildingList/Unitfloor/index.vue",
            query: {
              title: "房屋管理",
              recordId: record.id,
            },
          });
          break;
        //楼栋可视化
        case "visual":
          this.$router.push({
            path: "/views/communityGovernance/housingList/buildingList/Visual/index.vue",
            query: {
              title: "楼栋可视化",
              id: record.id,
            },
          });
          break;
        //删除
        case "del":
          break;
      }
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    //批量删除
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async () => {
            // 使用箭头函数确保this指向组件实例
            const res = await api.deleteBatch(this.selectedRowKeys);
            if (res.status === 200) {
              this.$message.success("删除成功");
              // 刷新列表数据
              this.$refs.roleTable.getDataSource();
            } else {
              this.$message.error("删除失败");
            }
          },
        });
      }
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.deleteById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.roleTable.getDataSource();
    },
    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
.titleBox {
  display: flex;
  align-items: center;
  font-size: 0.08rem;
  font-weight: 700;
  color: #2d3138;
  .titleLine {
    height: 0.09rem;
    width: 0.015rem;
    background-color: #2a5cff;
    margin-right: 5px;
  }
}
/deep/.is-current {
  color: #556bff !important;
}
/deep/.el-tree-node__content {
  height: 0.16rem;
  font-size: 0.35rem !important;
}
/deep/.el-tree-node__content {
  &:hover {
    background-color: #e8eef4;
  }
}
/deep/.el-tree-node.is-current > .el-tree-node__content {
  background-color: #e8eef4 !important;
}
</style>